<template >
  <div class="table-container">
    <div class="table-toolbar">
      <div class="title">
        <div class="text">
          <span>参数配置</span>
          <span class="desc">(系统基础数据)</span>
        </div>
        <a-button class="tit-btn" size="small" type="primary">
          <template #icon>
            <up-outlined />
          </template>
        </a-button>
      </div>
      <div class="opts">
        <a-button class="opt-btn" size="large" type="link" @click="toCreate">新建</a-button>
      </div>
    </div>
    <div class="table">
      <a-table size="middle" :columns="columns" :row-key="record => record.system_id" :data-source="dataSource"
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'setting_option'">
            <div>
              <a-tag v-for="item in record.setting_option" :key="item.id">{{ item.name }}</a-tag>
            </div>
          </template>
          <template v-else-if="column.dataIndex == 'icon'">
            <div v-if="record.icon">
              <FjList :fjlist="record.icon" />
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'operation'">
            <div class="operations">
              <a class="btn" @click="edit(record)">编辑</a>
              <!-- <a-button class="btn" type="primary" size="small">提交审核</a-button> -->
              <a-button type="danger" size="small" @click="del(record)">删除</a-button>
            </div>
          </template>
        </template>
      </a-table>
    </div>
    <a-drawer title="Create a new data" :width="720" :visible="flags.showEdit" :body-style="{ paddingBottom: '80px' }"
      :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="关键字" name="key">
              <a-input v-model:value="form.key" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="值" name="value">
              <a-input v-model:value="form.value" placeholder="请输入" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="附件" name="icon">
              <UploadFile v-model:value="form.icon" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-if="this.form.id">
          <a-col :span="24">
            <a-form-item label="选项">
              <OptionsToDo :id="this.form.id" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="描述" name="desc">
              <a-textarea v-model:value="form.desc" :rows="4" placeholder="请输入" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="flags.createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
import { usePagination } from 'vue-request';
import { computed, defineComponent } from 'vue';
import service from '@/service/service';
import { UpOutlined } from '@ant-design/icons-vue';
import { message, Modal } from 'ant-design-vue';
import UploadFile from '@/components/common/uploadFile.vue';
import FjList from '@/components/common/fjList.vue';
import OptionsToDo from './components/optionsToDo.vue';
const rules = {
  key: [{
    required: true,
    message: '请输入关键字',
  }],
  value: [{
    required: true,
    message: '请输入关键字',
  }],
};
const columns = [
  {
    title: '关键字',
    dataIndex: 'key',
  },
  {
    title: '值',
    dataIndex: 'value',
  },
  {
    title: '选项',
    dataIndex: 'setting_option',
    width: '30%'
  },
  {
    title: '附件',
    dataIndex: 'icon',
  },
  {
    title: '创建时间',
    dataIndex: 'input_time',
    sorter: true,
  },
  {
    title: '操作',
    dataIndex: 'operation'
  }
];
export default defineComponent({
  components: {
    UpOutlined,
    UploadFile,
    FjList,
    OptionsToDo
  },
  setup() {
    // 声明并调用一次数据拉取
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = usePagination(service.get_setting_lists, {
      formatResult: res => {
        res.data.total_num = res.total_num;
        return res.data;
      },
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'limit',
        totalKey: 'total_num',
      },
    });
    // 分页所需的属性
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
    }));

    // data用
    return {
      dataSource,
      pagination,
      loading,
      columns,
      rules,
      run,
    };
  },
  data() {
    return {
      currentTab: '第一个Tab',
      filters: {
        lx: undefined,
        lx2: undefined,
      },
      flags: {
        showEdit: false,
        createLoading: false,
      },
      form: {
        key: '',
        value: '',
        icon: "",
        id: '',
        desc: '',
      },
    }
  },
  methods: {
    // 切换页码调用获取
    handleTableChange(pag, filters, sorter) {
      this.run({
        limit: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order ? (sorter.order.includes('asc') ? 'asc' : 'desc') : '',
        ...this.filters,
      });
    },
    // 筛选
    handleSearch() {
      this.run({
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        ...this.filters
      })
    },
    edit(item) {
      // console.log('edit', id)
      const { icon, id, key, value, desc } = item;
      this.form = { id, key, value, desc };
      this.form.icon = icon;
      this.flags.showEdit = true;
    },
    del(item) {
      Modal.confirm({
        title: '警告',
        content: `确认删除[${item.key}]?`,
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          const { code, msg } = await service.delete_setting({ id: item.id });
          if (code && code == '1') {
            message.success(msg || '成功');
            this.handleSearch();
          }
          else {
            message.error(msg || '失败');
          }
        }
      });
    },
    toCreate() {
      // console.log('hello');
      this.flags.showEdit = true;
      this.form = {
        key: '',
        value: '',
        icon: '',
        id: '',
        desc: '',
      }
    },
    onClose() {
      this.flags.showEdit = false;
      this.$emit('close');
    },
    async onConfirm() {
      this.flags.createLoading = true;
      const api = this.form.id ? 'edit_setting' : 'add_setting';
      const { code, msg } = await service[api]({
        ...this.form
      })
      this.flags.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.handleSearch();
        this.onClose();
      }
    }
  }
})
</script>
<style lang="less" scoped></style>