<template>
  <div>
    <a-row :gutter="16">
      <template v-for="item in list" :key="item.id">
        <a-col :span="8">
          {{ item.name }}
        </a-col>
        <a-col :span="8">
          {{ item.value }}
        </a-col>
        <a-col :span="8">
          <a-button type="link" :loading="loading" @click="del(item)">删除</a-button>
        </a-col>
      </template>
    </a-row>
    <a-row :gutter="16" style="margin-top:12px;">
      <a-col :span="8">
        <a-input v-model:value="currentName" placeholder="选项名称" />
      </a-col>
      <a-col :span="8">
        <a-input v-model:value="currentValue" placeholder="选项值" />
      </a-col>
      <a-col :span="8">
        <a-button type="primary" :loading="loading" @click="submit">添加</a-button>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import service from '@/service/service';
import { message } from 'ant-design-vue';

export default {
  props: ['id'],
  data() {
    return {
      list: [],
      currentName: '',
      currentValue: '',
      loading: false,
    }
  },
  created() {
    this.getData();
  },
  methods: {
    async del(item) {
      this.loading = true;
      await service.delete_setting_option({ id: item.id });
      this.loading = false;
      this.getData();
    },
    async submit() {
      if (!this.currentName || !this.currentValue) {
        message.error('请完整输入');
        return;
      }
      this.loading = true;
      await service.add_setting_option({ setting_id: this.id, name: this.currentName, value: this.currentValue });
      this.loading = false;
      this.currentName = '';
      this.currentValue = '';
      this.getData();
    },
    async getData() {
      const { data } = await service.get_option_lists({ setting_id: this.id });
      this.list = data;
    }
  }
}
</script>
<style lang="less" scoped></style>