<template>
  <div>
    <a-upload @change="inputChange" v-model:fileList="fileList" name="logo" :action="uploadFile" list-type="picture">
      <a-button>
        <template #icon>
          <UploadOutlined />
        </template>
        点击上传
      </a-button>
    </a-upload>
  </div>
</template>

<script >
import service from '@/service/service';
import { Form } from 'ant-design-vue';
import { defineComponent } from 'vue';
import { UploadOutlined } from '@ant-design/icons-vue';
export default defineComponent({
  components: {
    UploadOutlined
  },
  props: {
    value: { isRequired: true },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const formItemContext = Form.useInjectFormItemContext();
    const triggerChange = (changedValue) => {
      emit('update:value', changedValue);
      formItemContext.onFieldChange();
    };
    const inputChange = ({ file, fileList }) => {
      // console.log(file);
      if (['done', 'removed'].includes(file.status)) {
        let files = fileList.map(m => ({ name: m.name, url: m.url || m.response?.url }));
        triggerChange(encodeURI(JSON.stringify(files)))
      }
    };
    return {
      inputChange,
      uploadFile: service.uploadFile,
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  watch: {
    value: {
      handler(val) {
        if (typeof val == 'string') {
          try {
            this.fileList = val ? JSON.parse(decodeURI(val)) : [];
          } catch (err) {
            this.fileList = [];
          }
        }
        else this.fileList = val;
      },
      immediate: true
    }
  }
})
</script>